<template>
  <main>
    <section class="card-wrapper">
      <CardFram :class="{ 'card-flip': flipCard }">
        <template #header>{{ $t("card.title") }}</template>
        <template #body>
          <form>
            <formWrapper :validator="$v.form">
              <b-row>
                <b-col
                  cols="12"
                  v-for="(formKey, index) in formKeys"
                  :key="index"
                  class="input-wrapper pa-0"
                >
                  <form-group
                    :name="formKey"
                    :attribute="`input.${formKey}`"
                    hint="fsdfsd fsdff"
                  >
                    <b-form-input
                      slot-scope="{ attrs, listeners }"
                      v-on="listeners"
                      v-bind="attrs"
                      v-model="form[formKey]"
                      v-inputFocus="{
                        index,
                        className: 'first-card-span',
                        formKey,
                        form
                      }"
                      aria-describedby="emailHelp"
                      class="first-card-input"
                    ></b-form-input>
                  </form-group>
                  <span
                    v-label-focus="{ index, className: 'first-card-input' }"
                    class="label-default first-card-span font-light"
                    >{{ $t(`input.${formKey}`) }}</span
                  >

                  <!-- <small
                    v-if="formKey === 'civil_registry_no'"
                    id="emailHelp"
                    class="form-text text-muted font-light"
                    >{{ $t("label.share_count_hint") }}</small
                  > -->
                </b-col>
              </b-row>
            </formWrapper>
          </form>
        </template>
        <template #footer>
          <b-col cols="12">
            <b-button
              :disabled="$v.form.$invalid"
              @click="handleNextCard"
              class="nextButton font-bold"
              variant="default"
              >{{ changeTextButton }}</b-button
            >
          </b-col>
        </template>
      </CardFram>

      <!-- back card  -->
      <CardFram class="card-back" :class="{ 'card-flip-back': flipCard }">
        <template #back-header>
          <b-col cols="12">
            <b-img
              class="checked-img"
              :src="require('@/assets/imgs/valideCard.svg')"
            ></b-img>
          </b-col>
        </template>
        <template #body>
          <b-row>
            <b-col cols="12" class="my-5">
              <b-list-group>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  v-for="(itemKey, index) in formKeys"
                  :key="index"
                >
                  <span class="font-regular">{{ $t(`input.${itemKey}`) }}</span>
                  <span class="font-regular">{{ form[itemKey] }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </template>
        <template #footer>
          <b-col cols="12" class="text-sm-center">
            <b-button
              @click="flipCard = !flipCard"
              class="nextButton edit-button font-bold "
              variant="default"
              >{{ $t("button.edit") }}</b-button
            >
          </b-col>
        </template>
      </CardFram>
    </section>
  </main>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  minValue,
  email
} from "vuelidate/lib/validators";
export default {
  name: "FirstCard",
  props: {
    showOverLay: {
      type: Boolean,
      default: false
    }
  },

  components: {
    CardFram: () => import("@/components/CardFram")
  },

  methods: {
    resetForm() {
      this.form = { ...this.defaultForm };
      this.$store.dispatch("ClearServerErrors");
      this.isEdit = false;
      this.$v.form.$reset();
    },
    handleNextCard() {
      this.isEdit = true;
      this.$emit("handle_next_card", "firstCardDone");
      this.$emit("first_card_data", this.form);
      this.flipCard = !this.flipCard;
    }
  },
  data() {
    return {
      flipCard: false,
      isNext: true,
      form: {
        name: "",
        phone: "",
        email: "",
        country: "",
        national_id_no: "",
        civil_registry_no: ""
      },
      defaultForm: {
        name: "",
        phone: "",
        email: "",
        country: "",
        national_id_no: "",
        civil_registry_no: ""
      },
      isEdit: false
    };
  },
  watch: {
    showOverLay: {
      handler(value) {
        if (!value) {
          this.resetForm();
        }
      },
      immediate: true
    }
  },

  computed: {
    formKeys() {
      return Object.keys(this.form);
    },
    changeTextButton() {
      if (this.isEdit) {
        return this.$t("button.save");
      } else {
        return this.$t("button.next");
      }
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150)
      },
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(14)
      },
      email: {
        email
      },
      country: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150)
      },
      national_id_no: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(150)
      },
      civil_registry_no: {
        minValue: minValue(2000)
      }
    }
  }
};
</script>
